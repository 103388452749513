<template>
  <div>
    <MainStepper e1="1" />
    <v-divider class="my-2"></v-divider>
    <v-card class="mt-1">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card-title>
          <span class="primary--text">CREATE REQUEST</span>
          <v-spacer class="mt-n3"></v-spacer>
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="text-capitalize mx-1"
                :loading="loading"
                @click="saveRequisition"
                elevation="0"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </template>
            <span>Save & continue </span>
          </v-tooltip>
          <v-tooltip top color="info">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                dark
                class="text-capitalize mx-1"
                :loading="loading"
                @click="gotToPreApproval"
                elevation="0"
                fab
                x-small
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>
            <span>Continue</span>
          </v-tooltip>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row class="mt-n5">
              <!-- First column  -->
              <v-col class="" cols="12" xl="9" lg="9" md="9" sm="12">
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.reference"
                      label="Reference *"
                      :rules="rules.required"
                      color="grey"
                      dense
                      filled
                      outlined
                      disabled
                      class="mb-n3"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="8">
                    <v-text-field
                      v-model="editedItem.title"
                      label="Title"
                      :rules="rules.required"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Request Name/Subject
                          <v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.amount"
                      label="Amount *"
                      :rules="rules.items"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-text-field
                      v-model="editedItem.historical_cost"
                      label="Historical cost "
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.currency_id"
                      :items="currencies"
                      label="Currency *"
                      :item-text="getCurrencyText"
                      item-value="id"
                      color="grey"
                      dense
                      outlined
                      class="d-flex align-end"
                      :rules="selectRules"
                    ></v-autocomplete>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.department_id"
                      :items="departments"
                      label="Cost center/ Department *"
                      item-text="title"
                      item-value="id"
                      color="grey"
                      dense
                      outlined
                      class="d-block mr-auto"
                      :rules="selectRules"
                      @change="selectDepartment"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                      v-model="editedItem.budget_availability"
                      :items="[
                        { id: 'yes', title: 'Yes' },
                        { id: 'no', title: 'No' },
                      ]"
                      label="Budget availability *"
                      item-text="title"
                      item-value="id"
                      color="grey"
                      dense
                      outlined
                      class="d-flex align-end"
                      :rules="selectRules"
                    ></v-select>
                  </v-col>

                  <v-col cols="4">
                    <v-select
                      v-model="editedItem.cost_type"
                      :items="[
                        { id: 'opex', title: 'OPEX' },
                        { id: 'capex', title: 'CAPEX' },
                      ]"
                      label="Cost Type *"
                      item-text="title"
                      item-value="id"
                      color="grey"
                      dense
                      outlined
                      class="d-flex align-end"
                      :rules="selectRules"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.category_id"
                      :items="supplier_groups"
                      label="Select category *"
                      item-text="title"
                      item-value="id"
                      color="grey"
                      dense
                      outlined
                      class="d-flex align-end"
                      :rules="selectRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.country_id"
                      :items="supported_countries"
                      label="Country *"
                      :item-text="getCountriesText"
                      item-value="id"
                      color="grey"
                      dense
                      outlined
                      class="d-flex align-end"
                      :rules="selectRules"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="4">
                    <v-autocomplete
                      v-model="editedItem.supplier_id"
                      :items="suppliers"
                      label="Supplier"
                      :item-text="getSupplierText"
                      item-value="id"
                      color="grey"
                      dense
                      outlined
                      class="d-flex align-end"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      v-model="editedItem.attachment_file"
                      color="grey"
                      append-icon="mdi-attachment"
                      prepend-icon=""
                      :label="
                        editedItem.amount > 500000
                          ? 'Attachments'
                          : 'Attachment *'
                      "
                      outlined
                      dense
                      @change="handleFileUpload"
                    >
                    </v-file-input>
                  </v-col>
                </v-row>
                <v-row class="mt-n5">
                  <v-col cols="12">
                    <label>Description</label>
                    <ckeditor
                      class="w-full"
                      tag-name="textarea"
                      ref="cktext"
                      :editor="editor"
                      v-model="editedItem.description"
                      shouldnotGroupWhenFull="true,"
                      :config="editorConfig"
                    ></ckeditor>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" sm="12" md="12" class="my-2">
                    <label>Quantifiable Items</label>
                    <v-card>
                      <div>
                        <v-card-title class="mb-n3 pt-n1">
                          <v-spacer></v-spacer>
                          <v-btn
                            color="info"
                            dark
                            class="text-capitalize mx-1 mt-n2"
                            v-if="editedIndex === -1"
                            v-on:click="addNewItem"
                            x-small
                            elevation="0"
                            fab
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <v-btn
                            color="teal"
                            dark
                            class="text-capitalize mx-1 mt-n2"
                            v-else
                            v-on:click="addNewEditedItem"
                            x-small
                            elevation="0"
                            fab
                          >
                            Add Item
                          </v-btn>
                        </v-card-title>
                        <v-divider></v-divider>
                        <v-card-text class="mt-n4">
                          <v-simple-table>
                            <template v-slot:default>
                              <thead>
                                <tr>
                                  <th class="text-left">#</th>
                                  <th class="text-left">Name</th>
                                  <th class="text-left">Quantity</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(
                                    requisition_item, i
                                  ) in requisition_items"
                                  :key="i"
                                >
                                  <td data-label="No.">{{ i + 1 }}</td>
                                  <td>
                                    <v-text-field
                                      v-model="requisition_item.name"
                                      label="Name"
                                      :rules="rules.items"
                                      color="grey"
                                      dense
                                      outlined
                                      class="mt-4"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label"
                                          >Name
                                          <v-icon
                                            small
                                            color="error"
                                            class="mt-n2"
                                            >mdi-star-outline</v-icon
                                          ></span
                                        >
                                      </template>
                                    </v-text-field>
                                  </td>

                                  <td>
                                    <v-text-field
                                      v-model="requisition_item.quantity"
                                      label="Quantity"
                                      :rules="rules.items"
                                      color="grey"
                                      dense
                                      outlined
                                      type="number"
                                      class="mt-4"
                                    >
                                      <template v-slot:label>
                                        <span class="input__label"
                                          >Quantity
                                          <v-icon
                                            small
                                            color="error"
                                            class="mt-n2"
                                            >mdi-star-outline</v-icon
                                          ></span
                                        >
                                      </template>
                                    </v-text-field>
                                  </td>

                                  <td>
                                    <v-btn
                                      color="error darken-1"
                                      class="mt-n2 mx-auto"
                                      fab
                                      x-small
                                      dark
                                      v-if="editedIndex === -1"
                                      v-on:click="
                                        deleteNewRequisitionItem(
                                          requisition_item
                                        )
                                      "
                                      elevation="0"
                                    >
                                      <v-icon dark> mdi-delete-outline </v-icon>
                                    </v-btn>

                                    <v-btn
                                      color="error darken-1"
                                      class="mx-1"
                                      fab
                                      x-small
                                      dark
                                      v-else
                                      v-on:click="
                                        deleteRequisitionItem(requisition_item)
                                      "
                                    >
                                      <v-icon dark> mdi-delete </v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-card-text>
                      </div>
                    </v-card>
                  </v-col>

                  <v-tooltip top color="info">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="primary"
                        dark
                        small
                        class="text-capitalize ml-auto mr-3"
                        :loading="loading"
                        @click="saveRequisition"
                        elevation="0"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small>mdi-content-save-outline</v-icon> Save
                      </v-btn>
                    </template>
                    <span>Save & continue </span>
                  </v-tooltip>
                </v-row>
              </v-col>
              <!-- Second column  -->
              <v-divider vertical></v-divider>
              <v-col class="mr-n1" cols="12" xl="3" lg="3" md="3" sm="12">
                <v-row class="ml-1">
                  <v-select
                    v-model="editedItem.workflow_id"
                    :items="workflows"
                    label="Approval workflow"
                    item-text="title"
                    item-value="id"
                    color="grey"
                    dense
                    outlined
                    class="d-block mr-auto"
                    :rules="selectRules"
                    @change="selectWorkflow"
                  ></v-select>
                </v-row>
                <v-row class="mt-n5">
                  <small class="ml-5 mt-3">
                    {{
                      selected_workflow.id
                        ? selected_workflow.description
                        : "No workflow selected"
                    }}</small
                  >
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        dark
                        class="text-capitalize ml-auto mt-1 info"
                        :loading="loading"
                        @click="newWorkflow"
                        elevation="0"
                        fab
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </template>
                    <span>Add new workflow</span>
                  </v-tooltip>
                </v-row>

                <v-row class="d-block mt-3">
                  <!-- samdoh  -->
                  <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                      <span class="" v-bind="attrs" v-on="on">
                        <v-btn
                          elevation="2"
                          text
                          fab
                          icon
                          small
                          class="info--text mx-auto d-flex"
                        >
                          <v-icon>mdi-account-group</v-icon>
                        </v-btn>
                        <h class="info--text d-flex justify-center">
                          Initiator</h
                        >
                      </span>
                    </template>
                    <span class="text--white">
                      <span class=""> Name : {{ user.name }} </span>
                    </span>
                  </v-tooltip>

                  <v-tooltip
                    right
                    :step="index + 1"
                    v-for="(group, index) in selected_approval_groups"
                    :key="index"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <span class="" v-bind="attrs" v-on="on">
                        <v-icon class="my-1 d-flex">mdi-arrow-down-thin</v-icon>
                        <v-btn
                          elevation="2"
                          text
                          fab
                          icon
                          small
                          class="info--text mx-auto d-flex"
                        >
                          <v-icon>mdi-account-group</v-icon>
                        </v-btn>
                        <h class="info--text d-flex justify-center">
                          {{ group.title }}</h
                        >
                      </span>
                    </template>
                    <span class="text--white">
                      <span class="">
                        {{ group.title }} :
                        <small> {{ group.description }} </small>
                      </span>
                      <ol class="mt-1">
                        <li v-for="user in group.users" :key="user.id">
                          {{ user.name }}
                        </li>
                      </ol>
                    </span>
                  </v-tooltip>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <!-- <v-card-actions>
          <v-btn
            color="primary"
            dark
            class="text-capitalize mx-1"
            :loading="loading"
            @click="gotToPreApproval"
            elevation="0"
          >
            Skip <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            dark
            class="text-capitalize mx-1"
            :loading="loading"
            @click="save"
            elevation="0"
          >
            Save & continue <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-card-actions> -->
      </v-form>
    </v-card>

    <!-- workflow modal -->
    <div class="text-center">
      <v-dialog v-model="workflow_modal" max-width="550px">
        <v-card>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title>
              <span class="headline error--text text--darken-1"
                >Create workflow</span
              >
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="12" class="my-n2">
                    <v-text-field
                      v-model="editedItemWorkflow.title"
                      label="Title"
                      :rules="rules.required"
                      color="grey"
                      dense
                      outlined
                      class="mb-n3"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Name
                          <v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12" class="my-n5">
                    <v-textarea
                      v-model="editedItemWorkflow.description"
                      outlined
                      name="input-7-4"
                      label="Tender Details"
                      color="grey"
                      dense
                      :rules="rules.required"
                      class="mb-n3"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Description
                          <v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                    </v-textarea>
                  </v-col>

                  <v-col cols="12" sm="12" md="12" class="mt-1">
                    <v-select
                      v-model="editedItemWorkflow.approval_groups"
                      :value="editedItemWorkflow.approval_groups"
                      :items="approval_groups"
                      :rules="rules.select2"
                      label="Approval Groups"
                      item-text="title"
                      item-value="id"
                      multiple
                      outlined
                      chips
                      color="grey"
                      item-color="grey"
                      class="my-1 mx-1"
                    >
                      <template v-slot:label>
                        <span class="input__label"
                          >Approval Groups
                          <v-icon small color="error" class="mt-n2"
                            >mdi-star-outline</v-icon
                          ></span
                        >
                      </template>
                      <template v-slot:prepend-item>
                        <v-list-item ripple @click="toggleRequired">
                          <v-list-item-action>
                            <v-icon
                              :color="
                                editedItemWorkflow.approval_groups.length > 0
                                  ? 'error'
                                  : ''
                              "
                            >
                              {{ iconRequired }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title> Select All </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item disabled>
                          <v-list-item-avatar color="grey lighten-3">
                            <v-icon> mdi-file-outline </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content v-if="likesAllApprovalGroup">
                            <v-list-item-title>
                              All Documents selected for mandatory fields!
                            </v-list-item-title>
                          </v-list-item-content>

                          <v-list-item-content
                            v-else-if="likesSomeapproval_groups"
                          >
                            <v-list-item-title>
                              Document Count
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              {{ editedItemWorkflow.approval_groups.length }}
                            </v-list-item-subtitle>
                          </v-list-item-content>

                          <v-list-item-content v-else>
                            <v-list-item-title>
                              Which documents are mandatory for this tender?
                            </v-list-item-title>
                            <v-list-item-subtitle>
                              Go ahead, make a selection above!
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                class="text-capitalize mx-1"
                dark
                @click="close"
              >
                Cancel <v-icon small>mdi-cancel</v-icon>
              </v-btn>
              <v-btn
                color="primary"
                dark
                class="text-capitalize mx-1"
                :loading="loading"
                @click="saveWorkflow"
              >
                Submit <v-icon>mdi-content-save-outline</v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </div>

    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1400"
      filename="hee hee"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      ref="html2Pdf"
    >
      <section slot="pdf-content" id="printarea">
        <!-- PDF Content Here -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<style>
.ck-editor__editable {
  min-height: 250px;
}
.in-editor .ck-toolbar {
  display: inline !important;
}
</style>
<script>
import RequisitionService from "../../services/requisition.service";
import QuotationRequestService from "../../services/quotation_request.service";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import WorkflowService from "../../services/workflow.service";
import DepartmentService from "../../services/department.service";
import CurrencyService from "../../services/currency.service";
import SupportedCountryService from "../../services/supported_countries.service";
import SupplierService from "../../services/suppliers.service";

import SupplierCategoryService from "../../services/supplier_category.service";
import VueHtml2pdf from "vue-html2pdf";
import MainStepper from "../../components/MainStepper.vue";
import moment from "moment";

// import {
//   Heading,
//   Bold,
//   Italic,
//   Strike,
//   Underline,
//   Code,
//   Paragraph,
//   BulletList,
//   OrderedList,
//   ListItem,
//   Link,
//   Blockquote,
//   HardBreak,
//   HorizontalRule,
//   History,
// } from "tiptap-vuetify";

export default {
  components: { VueHtml2pdf, MainStepper },
  data: () => ({
    e1: 1,
    name: "Need Identification",
    countries: [
      { id: 1, name: "Kenya" },
      { id: 2, name: "Uganda" },
      { id: 3, name: "Tanzania" },
    ],
    selected_workflow: {},
    selected_approval_groups: {},
    approval_groups: [],
    approval_group_ids: [],
    editedItemWorkflow: {
      approval_groups: [],
      approval_group_ids: [],
    },
    defaultItemWorkflow: {
      approval_groups: [],
      approval_group_ids: [],
    },
    workflow_modal: false,
    workflows: [],
    currencies: [],
    departments: [],
    categories: [],
    supplier_groups: [],
    suppliers: [],
    supported_countries: [],
    selectRules: [(v) => !!v || "Select is required"],
    dialog_view: false,
    dialogSendToSuppliers: false,
    editor: ClassicEditor,
    editorConfig: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },
    editorConfig2: {
      viewportTopOffset: 50,
      shouldNotGroupWhenFull: true,
      editorplaceholder: "Details here",
      toolbarCanCollapse: false,
      readOnly: true,
      toolbar: {
        viewportTopOffset: 30,
        shouldNotGroupWhenFull: true,
      },
    },

    loading: true,
    items: [],
    requisition_items: [],
    search: "",
    editedIndex: -1,
    editedItem: {
      reference: "PPA" + moment().format("yyMMDDhhmmss"),
      amount: "",
      historical_cost: "",
      attachment: "",
      attachment_file: [],
      requisition_items: [],
    },
    defaultItem: {
      reference: "PPA" + moment().format("yyMMDDhhmmss"),
      amount: "",
      historical_cost: "",
      attachment: "",
      attachment_file: [],
      requisition_items: [],
    },
    remember: 0,
    href: "workflows",
    timeout: 2000,
    dialog: false,
    valid: true,
    valid1: true,
    // declare extensions you want to use
    // extensions: [
    //   History,
    //   Blockquote,
    //   Link,
    //   Underline,
    //   Strike,
    //   Italic,
    //   ListItem,
    //   BulletList,
    //   OrderedList,
    //   [
    //     Heading,
    //     {
    //       options: {
    //         levels: [1, 2, 3],
    //       },
    //     },
    //   ],
    //   Bold,
    //   Code,
    //   HorizontalRule,
    //   Paragraph,
    //   HardBreak,
    // ],
    rules: {
      select: [(v) => !!v || "An item should be selected"],
      select2: [(v) => v.length > 0 || "Atleast one item should be selected"],
      required: [
        (v) => !!v || "Field is required",
        (v) => (v && v.length >= 3) || "Min 3 characters",
      ],
      file: [
        (v) => !!v || "File is required",
        (v) => (v && v.length > 0) || "File is required",
      ],
      items: [(v) => !!v || "Field is required"],
    },
    headers: [
      {
        text: "#",
        align: "start",
        filterable: true,
        value: "id",
      },
      {
        text: "Title",
        value: "title",
      },
      {
        text: "Workflow",
        value: "workflow.title",
      },
      {
        text: "Workflow Steps",
        value: "workflow",
      },
      {
        text: "Status",
        value: "status",
      },
      {
        text: "Attached Document",
        value: "attachment",
      },
      {
        text: "Amount(USD)",
        value: "amount",
      },
      {
        text: "Actions",
        align: "center",
        value: "actions",
        sortable: false,
        width: "20%",
      },
    ],
  }),
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New" : "Edit";
    },
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    getLoading() {
      return this.loading;
    },
    likesAllApprovalGroup() {
      return (
        this.editedItemWorkflow.approval_groups.length ===
        this.approval_groups.length
      );
    },
    likesSomeapproval_groups() {
      return (
        this.editedItemWorkflow.approval_groups.length > 0 &&
        !this.likesAllApprovalGroup
      );
    },
    iconRequired() {
      if (this.likesAllApprovalGroup) return "mdi-close-box";
      if (this.likesSomeapproval_groups) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.index();
    this.getSuppliers();
    this.getAllGroups();
    this.getAllCurrency();
    this.getAllWorkflows();
    this.getAllDepartments();
    this.getSupplierCategories();
    this.getAllSupportedCountry();
  },
  methods: {
    getCurrencyText(item) {
      return `${item.code} - ${item.name}`;
    },
    getCountriesText(item) {
      return `${item.country.emoji} ${item.country.name}`;
    },
    getSupplierText(item) {
      return `${item.company.company_name} - ${item.name}`;
    },
    selectWorkflow(e) {
      console.group("Workflow selected..");
      this.selected_workflow = this.workflows.find((i) => i.id == e);
      this.selected_approval_groups = this.selected_workflow.approval_groups;
      console.log(this.selected_workflow);
      console.log(this.selected_approval_groups);
    },
    selectDepartment(e) {
      console.log(e);
    },
    toggleRequired() {
      this.$nextTick(() => {
        if (this.likesAllApprovalGroup) {
          this.editedItemWorkflow.approval_groups = [];
          this.editedItemWorkflow.approval_group_ids = [];
        } else {
          this.editedItemWorkflow.approval_groups =
            this.approval_groups.slice();
          this.editedItemWorkflow.approval_group_ids =
            this.approval_group_ids.slice();
        }
      });
    },
    getAllGroups() {
      return WorkflowService.getApprovalGroups().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.approval_groups = response.data.data;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
        }
      );
    },
    newWorkflow() {
      this.workflow_modal = true;
    },

    toMoneyFormat(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    getAllSupportedCountry() {
      return SupportedCountryService.index().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.supported_countries = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getSuppliers() {
      return SupplierService.index().then(
        (response) => {
          if (response.status == 200) {
            this.suppliers = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getSupplierCategories() {
      return SupplierCategoryService.index().then(
        (response) => {
          if (response.status == 200) {
            this.supplier_groups = response.data.data;
            this.loading = false;
          } else {
            this.supplier_groups = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.supplier_groups = [];
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    addNewItem() {
      this.requisition_items.push({ name: "", quantity: "" });
    },
    addNewEditedItem() {
      this.requisition_items.push({ name: "", quantity: "", edited: 1 });
    },
    generateReport() {
      var printable_content = document.getElementById("printable_content");
      var pdf = document.getElementById("printarea");
      pdf.innerHTML = printable_content.innerHTML;
      this.$refs.html2Pdf.generatePdf();
    },

    getAllDepartments() {
      return DepartmentService.index().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.departments = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    getAllCurrency() {
      return CurrencyService.index().then(
        (response) => {
          console.log(response);
          if (response.status == 200) {
            this.currencies = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    },

    getAllWorkflows() {
      return WorkflowService.index().then(
        (response) => {
          if (response.status == 200) {
            this.workflows = response.data.data;
          } else {
            this.roles = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.roles = [];
          console.log(error);
        }
      );
    },

    trimText(text, length) {
      text.substring(0, length);
    },
    editItem(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialog = true;
    },
    viewItemModal(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialog_view = true;
    },
    sendItemToSupplier(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.requisition_items = this.editedItem.items;
      this.dialogSendToSuppliers = true;
    },

    // search
    filterText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    gotToPreApproval() {
      this.$router.push("/requisition");
    },
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    index() {
      return RequisitionService.index().then(
        (response) => {
          if (response.status == 200) {
            this.items = response.data.data;
            this.loading = false;
          } else {
            this.items = [];
            console.log(response.data.error);
          }
        },
        (error) => {
          this.items = [];
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    viewItem(item) {
      this.$router.push("/suppliers-view/" + item.id);
    },
    sendToEboard(item) {
      item.approval_name = item.title;
      item.applicant_name = this.user.name;
      const index = this.items.indexOf(item);
      var sendConfirm = confirm("Confirm sending item for eBoard approval?");
      if (sendConfirm) {
        RequisitionService.sendToEboard(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
              this.index();
            } else {
              this.$store.dispatch("alert/error", "Error sending approval");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },

    deleteItem(item) {
      const index = this.items.indexOf(item);
      var deleteUser = confirm("Are you sure you want to delete this item?");
      if (deleteUser) {
        RequisitionService.delete(item).then(
          (response) => {
            if (response.status == 200) {
              this.$store.dispatch("alert/success", response.data.message);
              this.items.splice(index, 1);
              this.close();
            } else {
              this.$store.dispatch("alert/error", "Error deleting document");
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 500) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 502) {
              this.$store.dispatch("alert/error", error.response.data.message);
            } else if (error.response.status == 401) {
              this.handleLogout();
            } else {
              this.$store.dispatch("alert/error", error.response.statusText);
            }
          }
        );
      }
    },
    deleteNewRequisitionItem(item) {
      const index = this.requisition_items.indexOf(item);
      var deleteReqItem = confirm("Are you sure you want to delete this item?");
      if (deleteReqItem) {
        this.requisition_items.splice(index, 1);
      }
    },
    deleteRequisitionItem(item) {
      const index = this.requisition_items.indexOf(item);
      var deleteReqItem = null;
      if (item.edited == 1) {
        deleteReqItem = confirm("Are you sure you want to delete this item?");
        if (deleteReqItem) {
          this.requisition_items.splice(index, 1);
        }
      } else {
        deleteReqItem = confirm("Are you sure you want to delete this item?");
        if (deleteReqItem) {
          RequisitionService.deleteItem(item).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.requisition_items.splice(index, 1);
              } else {
                this.$store.dispatch("alert/error", "Error deleting document");
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              } else if (error.response.status == 500) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              } else if (error.response.status == 502) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
              } else if (error.response.status == 401) {
                this.handleLogout();
              } else {
                this.$store.dispatch("alert/error", error.response.statusText);
              }
            }
          );
        }
      }
    },

    close() {
      this.workflow_modal = false;
    },

    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },

    async handleFileUpload(file) {
      console.log("uploading...");
      await this.getBase64(file).then(
        (data) => (this.editedItem.attachment = data)
      );
    },
    saveRequisition() {
      this.loading = true;
      this.editedItem.initiator_id = this.user.id;
      this.valid = this.$refs.form.validate();
      if (this.valid) {
        this.editedItem.requisition_items = this.requisition_items;
        // edit
        if (this.editedIndex > -1) {
          Object.assign(this.items[this.editedIndex], this.editedItem);
          try {
            if (this.editedItem.required_documents[0].id) {
              this.editedItem.required_documents =
                this.editedItem.required_document_ids;
            }
          } catch (error) {
            console.log(error);
          }
          console.log(this.editedItem);
          RequisitionService.update(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.$store.dispatch("alert/success", response.data.message);
                this.loading = false;
                this.$router.push("/requisition");
                this.close();
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
            }
          );
        } else {
          // create
          RequisitionService.store(this.editedItem).then(
            (response) => {
              if (response.status == 200) {
                this.items.push(this.editedItem);
                this.$store.dispatch(
                  "alert/success",
                  "Item created successfully"
                );
                this.loading = false;
                this.index();
                this.$router.push("/requisition");
              } else {
                this.$store.dispatch("alert/error", response.data.message);
                this.index();
                this.loading = false;
              }
            },
            (error) => {
              if (error.response.status == 422) {
                this.$store.dispatch(
                  "alert/error",
                  error.response.data.message
                );
                this.loading = false;
                this.index();
              }
              if (error.response.status == 401) {
                this.handleLogout();
                this.loading = false;
                this.index();
              }
              console.log(error);
              this.$store.dispatch("alert/error", error.response.data.message);
            }
          );
        }
      } else {
        this.loading = false;
        this.index();
      }
      this.loading = false;
    },
    requestQuotation() {
      this.loading = true;
      this.$refs.form1.validate();
      if (this.valid) {
        this.editedItem.requisition_id = this.editedItem.id;
        this.editedItem.user_id = this.user.id;
        QuotationRequestService.store(this.editedItem).then(
          (response) => {
            console.log(response);
            if (response.status == 200) {
              this.$store.dispatch(
                "alert/success",
                "Request sent successfully"
              );
              this.loading = false;
              this.dialogSendToSuppliers = false;
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.dialogSendToSuppliers = false;
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.dialogSendToSuppliers = false;
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.dialogSendToSuppliers = false;
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.index();
      }
    },
    saveWorkflow() {
      this.loading = true;
      this.$refs.form.validate();
      if (this.valid) {
        // create
        WorkflowService.store(this.editedItemWorkflow).then(
          (response) => {
            console.group("Workflow");
            console.group(response);
            if (response.status == 200) {
              this.items.push(this.editedItemWorkflow);
              this.$store.dispatch(
                "alert/success",
                "Item created successfully"
              );
              this.loading = false;
              this.workflow_modal = false;
              this.getAllWorkflows();
              this.close();
            } else {
              this.$store.dispatch("alert/error", response.data.message);
              this.index();
            }
          },
          (error) => {
            if (error.response.status == 422) {
              this.$store.dispatch("alert/error", error.response.data.message);
              this.loading = false;
              this.index();
            }
            if (error.response.status == 401) {
              this.handleLogout();
              this.loading = false;
              this.index();
            }
            console.log(error);
            this.$store.dispatch("alert/error", error.response.data.message);
          }
        );
      } else {
        this.loading = false;
        this.getAllWorkflows();
      }
    },
  },
};
</script>
